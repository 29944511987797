// src/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';
import twitterSvg from './twitter.png'; 
import tgSvg from './tg.png'; 

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">


        <Col sm={6} md={3}>
            <h5>$BORT</h5>
            <img
              src="/logo.svg"
              width="100"
              height="100"
              className="d-inline-block align-top vibration"
              alt="Maxx logo"
            />
          </Col>
        <Col sm={12} md={3}>
  <h5>Socials</h5>
  <p>Join us now!</p>
  <div className="social-icons">
    <a href="https://x.com/XSolToken" target="_blank" rel="noopener noreferrer">
      <img src={twitterSvg} alt="Twitter" />
    </a>
    <a href="https://t.me/XSolanaToken" target="_blank" rel="noopener noreferrer">
      <img src={tgSvg} alt="Telegram" />
    </a>
  </div>
</Col>

<Col sm={6} md={3}>
  <h5>Links</h5>
  <ul>
    <li><a href="" target="_blank" rel="noopener noreferrer">Buy $XSOL</a></li>
    <li><a href="" target="_blank" rel="noopener noreferrer">$XSOL Chart</a></li>
  </ul>
</Col>

          <Col sm={6} md={3}>
            <h5>$XSOL</h5>
            <img
              src="/logo.svg"
              width="100"
              height="100"
              className="d-inline-block align-top vibration"
              alt="Maxx logo"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="footer-bottom text-center">
              <p>Copyright © 2024 XSolana All rights reserved.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
