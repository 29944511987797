// src/Section4.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Section3.css'; 

const Section3 = () => {
  return (
    <Container className="section4 text-center" id='joinus'>
      <Row>
        <Col>
        <h1 className="section4-title">Join us</h1>
          <p className="section4-text">
        Check out latest Solana announcement to learn more about $XSOL. More details to come <br /><br></br>
        <p  style={{ textDecoration: 'underline', color: 'white' }}><a 
          href="https://x.com/solana/status/1805587979723063440" 
          target="_blank" 
          style={{ textDecoration: 'underline', color: 'white' }}
        >
          Find out more
        </a>
        </p>
      </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Section3;
